import { ManageProductionTagsModalActions } from 'pages/production/controllers/manage-production-tags-modal.controller';
import { ProductionTag } from 'pages/production/controllers/types';
import { COLORS } from 'pages/production/production-tag-editor-modal/constants';
import { GetStateFunction } from 'redux/store';
import { StateController } from 'state-controller';

export type ProductionTagEditorArgs = {
  tag?: ProductionTag;
  mode: 'create' | 'edit';
};

export type TagEditorState = {
  isOpen: boolean;
  tag: ProductionTag;
  mode: ProductionTagEditorArgs['mode'];
};

const defaultState: TagEditorState = {
  isOpen: false,
  mode: 'create',
  tag: {
    id: '',
    name: '',
    checked: false,
    background: COLORS[0],
  },
};

const stateController = new StateController<TagEditorState>('TAG_EDITOR_MODAL', defaultState);

export class ProductionTagEditorActions {
  public static openModal({ tag, mode }: ProductionTagEditorArgs) {
    return async (dispatch) => {
      dispatch(stateController.setState({ isOpen: true, tag: tag || defaultState.tag, mode }));
    };
  }

  public static closeModal() {
    return async (dispatch) => {
      dispatch(stateController.setState({ isOpen: false }));
    };
  }

  public static handleChangeName(value: string) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          tag: {
            ...prev.tag,
            name: value,
          },
        })),
      );
    };
  }

  public static handleChangeColor(color: string) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          tag: {
            ...prev.tag,
            background: color,
          },
        })),
      );
    };
  }

  public static handleDelete() {
    return async (dispatch, getState: GetStateFunction) => {
      const { tag } = getState().production.tagEditorModal;

      dispatch(ManageProductionTagsModalActions.deleteTag(tag));
      dispatch(stateController.setState({ isOpen: false }));
    };
  }

  public static handleSave() {
    return async (dispatch, getState: GetStateFunction) => {
      const { tag } = getState().production.tagEditorModal;

      dispatch(ManageProductionTagsModalActions.addTag(tag));
      dispatch(stateController.setState({ isOpen: false }));
    };
  }
}

export const productionTagEditorModalReducer = stateController.getReducer();
