export const COLORS = [
  '#FFF2F9',
  '#F9F1FE',
  '#FFF3F2',
  '#EBF6FF',
  '#E9F7EE',
  '#FFF3E1',
  '#F0F6DD',
  '#FDFAE0',
  '#EEFDFF',
  '#FFEDEE',
  '#EAF0FF',
  '#FFEFF7',
  '#F4FBF7',
  '#FFF6FF',
  '#EDF5FB',
];

export const MAX_INPUT_LENGTH = 255;
