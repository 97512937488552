import { StateController } from 'state-controller';

export type DeleteProductionTagModalState = {
  isOpen: boolean;
};

const defaultState: DeleteProductionTagModalState = {
  isOpen: false,
};

const stateController = new StateController<DeleteProductionTagModalState>('DELETE_PRODUCTION_TAG_MODAL', defaultState);

export class DeleteProductionTagModalActions {
  public static openModal() {
    return async (dispatch) => {
      dispatch(stateController.setState({ isOpen: true }));
    };
  }

  public static closeModal() {
    return async (dispatch) => {
      dispatch(stateController.setState({ isOpen: false }));
    };
  }
}

export const deleteProductionTagModalReducer = stateController.getReducer();
