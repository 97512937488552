import { StateController } from 'state-controller';
import { ProductionWorkflow, ProductionWorkflowInfo } from 'services/production-workflow.model';

import { ProductionTag } from 'pages/production/controllers/types';

export type ManageProductionTagsArgs = {
  production: ProductionWorkflow | ProductionWorkflowInfo;
};

export type ManageProductionTagsState = {
  isOpen: boolean;
  productionInfo: {
    id: string;
    productName: string;
    tags: ProductionTag[];
  };
  newTags: ProductionTag[];
};

const defaultState: ManageProductionTagsState = {
  isOpen: false,
  productionInfo: {
    id: '',
    productName: '',
    tags: [],
  },
  newTags: [],
};

const stateController = new StateController<ManageProductionTagsState>('MANAGE_PRODUCTION_TAGS_MODAL', defaultState);

export class ManageProductionTagsModalActions {
  public static openModal({ production }: ManageProductionTagsArgs) {
    return async (dispatch) => {
      dispatch(
        stateController.setState({
          isOpen: true,
          productionInfo: {
            productName: production.product_name,
            id: production.id,
            tags: [],
          },
        }),
      );
    };
  }

  public static closeModal() {
    return async (dispatch) => {
      dispatch(stateController.setState({ isOpen: false }));
    };
  }

  public static handleCheckedChange(tagId: string) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          productionInfo: {
            ...prev.productionInfo,
            tags: prev.productionInfo.tags.map((tag) => (tag.id === tagId ? { ...tag, checked: !tag.checked } : tag)),
          },
        })),
      );
    };
  }

  public static addTag(tag: ProductionTag) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          productionInfo: {
            ...prev.productionInfo,
            tags: prev.productionInfo.tags.some(({ id }) => id === tag.id)
              ? prev.productionInfo.tags.map((existingTag) => (existingTag.name === tag.name ? tag : existingTag))
              : [...prev.productionInfo.tags, tag],
          },
        })),
      );
    };
  }

  public static deleteTag(tag: ProductionTag) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          productionInfo: {
            ...prev.productionInfo,
            tags: prev.productionInfo.tags.filter(({ id }) => tag.id !== id),
          },
        })),
      );
    };
  }
}

export const manageProductionTagsModalReducer = stateController.getReducer();
